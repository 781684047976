import React from "react";
import styles from './TextGrid.module.scss';
import { Trans, useTranslation } from "react-i18next";
import { Fade } from "react-reveal";

import image from '../../../assets/images/homepage/image2.webp';

const TextGrid = () => {
  const { t } = useTranslation();
  const list1 = t('main.second.list1', { returnObjects: true });
  const list2 = t('main.second.list2', { returnObjects: true });

  return (
    <section className={styles.wrap}>
      <div className={`${styles.container} container`}>
          <div className={styles.list}>
            {list1.map((item, index) => (
            <div className={`${styles.listItem} ${index === 0 ? styles.first : ''}`} key={index}>
              <Fade right delay={index * 100 + 1}>
                <div className={`${styles.listTitle} font-36-28`}>
                  <Trans>{t(`${item.title}`)}</Trans>
                </div>
                <div className={`${styles.listText} font-20-16`}>
                  <Trans>{t(`${item.text}`)}</Trans>
                </div>
              </Fade>
              </div>
            ))}
          </div>
          <Fade left delay={100}>
            <div>
              <img src={image} alt="" className={styles.image} />
            </div>
          </Fade>
          <div className={`${styles.list} ${styles.listLast}`}>
            {list2.map((item, index) => (
              <div className={styles.listItem} key={index}>
                <Fade right delay={index * 100 + 1}>
                  <div className={`${styles.listTitle} font-36-28`}>
                    <Trans>{t(`${item.title}`)}</Trans>
                  </div>
                  <div className={`${styles.listText} font-20-16`}>
                    <Trans>{t(`${item.text}`)}</Trans>
                  </div>
              </Fade>
              </div>
            ))}
          </div>
      </div>
    </section>
  );
};

export default TextGrid;