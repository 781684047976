import React from "react";
import styles from './TextImgGrid.module.scss';
import { Trans, useTranslation } from "react-i18next";
import { Fade } from "react-reveal";

import image1 from '../../../assets/images/homepage/image4.webp';
import image2 from '../../../assets/images/homepage/image5.webp';
import image3 from '../../../assets/images/homepage/image6.webp';

const TextImgGrid = () => {
  const { t } = useTranslation();
  const list = t('main.five.list', { returnObjects: true });
  const images = [image1, image2, image3];

  return (
    <section className={styles.wrap}>
      <div className={`${styles.container} container`}>
        <div className={`${styles.title} font-40-28`}><Trans>{t('main.five.title')}</Trans></div>
          <div className={styles.list}>
            {list.map((item, index) => (
              <Fade left={index % 2 === 0} right={index % 2 !== 0} duration={1000} delay={index * 100 + 100} key={index}>
                <div className={styles.listItem}>
                  <div className={styles.textBlock}>
                    <div className={`${styles.listItemTitle} font-32-24`}>
                      <Trans>{t(`${item.title}`)}</Trans>
                    </div>
                    <div className={`${styles.listItemText} font-16-14`}>
                      <Trans>{t(`${item.text}`)}</Trans>
                    </div>
                  </div>
                  <div className={styles.imgWrap}>
                    <img src={images[index]} alt="" />
                  </div>
                </div>
              </Fade>
            ))}
          </div>
      </div>
    </section>
  );
};

export default TextImgGrid;