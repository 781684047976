import React from "react";
import styles from './ListText2.module.scss';
import { Trans, useTranslation } from "react-i18next";

const ListText2 = () => {
  const { t } = useTranslation();
  const list = t('promotion.six.list1', { returnObjects: true });
  const list1 = t('promotion.six.list2', { returnObjects: true });

  return (
    <section className={styles.wrap}>
      <div className={`${styles.container} container`}>
        <div className={styles.item}>
          <div className={`${styles.title} font-40-28`}>{t('promotion.six.title1')}</div>
          <div className={styles.list}>
            {list.map((item, index) => (
              <div className={`${styles.listItem} font-16-14`} key={index}><Trans>{t(`${item.title}`)}</Trans></div>
            ))}
          </div>
        </div>

        <div className={styles.item}>
          <div className={styles.list}>
          <div className={`${styles.title} font-40-28`}>{t('promotion.six.title2')}</div>
            {list1.map((item, index) => (
              <div className={`${styles.listItem} font-16-14`} key={index}>{t(`${item.title}`)}</div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ListText2;