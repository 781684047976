import React from "react";
import styles from './BgText.module.scss';
import { useTranslation } from "react-i18next";

import image from '../../../assets/images/about/image5.webp';

const BgText = () => {
  const { t } = useTranslation();

  return (
    <section className={styles.wrap}>
      <div className={`${styles.container} container`}>
        <div className={styles.item}>
          <div className={`${styles.title} font-40-28`}>{t('about.third.title')}</div>
          <div className={`${styles.text} font-16-14`}>{t('about.third.text0')}</div>
        </div>
        <div className={styles.item}>
        <div className={`${styles.text} ${styles.textItalic} font-20-16`}>{t('about.third.text1')}</div>
          <img src={image} alt="" className={styles.image} />
        </div>
      </div>
    </section>
  );
};

export default BgText;