import React from "react";
import styles from './Banner.module.scss';
import { Trans, useTranslation } from "react-i18next";
import Marquee from "react-fast-marquee";
import { Fade } from "react-reveal";

const Banner = () => {
  const { t } = useTranslation();
  const list = t('why.first.list', { returnObjects: true });
  const marqueeList = [...list, ...list];

  return (
    <section className={styles.wrap}>
      <div className={`${styles.container} container`}>
        <div className={styles.row}>
          <Fade right><h1 className={`${styles.title} font-48-28`}><Trans>{t('why.first.title')}</Trans></h1></Fade>
          <Fade left delay={50}><h5 className={`${styles.text} font-16-14`}><Trans>{t('why.first.text')}</Trans></h5></Fade>
        </div>

        <div className={styles.marqueeWrap}>
          <Marquee>
            {marqueeList.map((item, index) => (
              <div className={styles.marqueeItem} key={index}><Trans>{t(`${item.title}`)}</Trans></div>
            ))}
          </Marquee>
        </div>
      </div>
    </section>
  );
};

export default Banner;