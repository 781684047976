import React, { useState } from 'react';
import { Trans, useTranslation } from "react-i18next";
import Button from '../../GlodalComponents/Button/Button';
import { Fade } from "react-reveal";

import image1 from '../../../assets/images/trading/image1.webp';
import image2 from '../../../assets/images/trading/image2.webp';
import image3 from '../../../assets/images/trading/image3.webp';
import bg0 from '../../../assets/images/trading/bgs/image0.webp';
import bg1 from '../../../assets/images/trading/bgs/image1.webp';
import bg2 from '../../../assets/images/trading/bgs/image2.webp';
import bg3 from '../../../assets/images/trading/bgs/image3.webp';
import bg4 from '../../../assets/images/trading/bgs/image4.webp';
import bg5 from '../../../assets/images/trading/bgs/image5.webp';
import bg6 from '../../../assets/images/trading/bgs/image6.webp';

import styles from './Tabs.module.scss';

const Tabs = () => {
  const [activeTab, setActiveTab] = useState(0);
  const { t } = useTranslation();

  const handleTabClick = (id) => {
    setActiveTab(id);
  };

  const images = [image1, image2, image3];
  const bgs = [bg0, bg1, bg2, bg3, bg4, bg5, bg6];

  const tabContent = t('trading.titles.list', { returnObjects: true });
  const list1 = t('trading.tab1.list1', { returnObjects: true });
  const listbot1 = t('trading.tab1.list2', { returnObjects: true });
  const list2 = t('trading.tab2.list', { returnObjects: true });
  const list3 = t('trading.tab3.list', { returnObjects: true });
  const list4 = t('trading.tab4.list', { returnObjects: true });
  const list5 = t('trading.tab5.list', { returnObjects: true });
  const list6 = t('trading.tab6.list', { returnObjects: true });
  const list7 = t('trading.tab7.list', { returnObjects: true });

  return (
    <div className={styles.wrap}>
      <div className={`${styles.container} container`}>
        <div className={styles.tabButtons}>
          {tabContent.map((tab, index) => (
            <Fade bottom delay={index * 10 + 100} key={index}>
              <button
                key={tab.id}
                className={tab.id === activeTab ? styles.tabActive : ''}
                onClick={() => handleTabClick(tab.id)}
              >
                {index > 0 && ( <span>/</span> )}
                <div className={styles.tabTitle}>{t(`${tab.title}`)}</div>
              </button>
            </Fade>
          ))}
        </div>

        <div className={styles.tabsContent}>
          <div className={`${styles.tabItem} ${0 === activeTab ? styles.tabItemActive : ''}`}>
            <div className={styles.firstBlock}>
              <div className={`${styles.firstBlockTitle} font-40-28`}>{t('trading.tab1.title')}</div>
              <div className={`${styles.firstBlockText} font-16-14`}>{t('trading.tab1.text')}</div>
              <div className={`${styles.btn} mt-btn`}><Button bold>{t('btn.start')}</Button></div>
              <div className={styles.imgWrap}><img src={bgs[0]} alt='' /></div>
            </div>

            <div className={styles.gridListWrap}>
              <div className={`${styles.tabItemTitle} font-32-24`}>{t('trading.tab1.list1.title')}</div>
              <div className={styles.gridList}>
                {list1.map((item, index) => (
                  <div className={styles.gridListItem} key={index}>
                    <div className={`${styles.gridListItemTitle} font-24-20`}>{t(`${item.title}`)}</div>
                    <div className={`${styles.gridListItemText} font-16-14`}>{t(`${item.text}`)}</div>
                  </div>
                ))}
              </div>
            </div>

            <div className={styles.textListWrap}>
              <div className={`${styles.tabItemTitle} font-40-28`}>{t('trading.tab1.list2.title')}</div>
              <div className={styles.textList}>
                {listbot1.map((item, index) => (
                  <Fade left={index % 2 === 0} right={index % 2 !== 0} duration={1000} delay={index * 100 + 100} key={index}>
                    <div className={styles.textListItem}>
                      <div className={styles.textBlock}>
                        <div className={`${styles.textListItemTitle} font-24-20`}>
                          <Trans>{t(`${item.title}`)}</Trans>
                        </div>
                        <div className={`${styles.textListItemText}`}>
                          <Trans>{t(`${item.text}`)}</Trans>
                        </div>
                      </div>
                      <div className={styles.imgWrap}>
                        <img src={images[index]} alt="" />
                      </div>
                    </div>
                  </Fade>
                ))}
              </div>
            </div>
          </div>

          <div className={`${styles.tabItem} ${1 === activeTab ? styles.tabItemActive : ''}`}>
            <div className={styles.firstBlock}>
              <div className={`${styles.firstBlockTitle} font-40-28`}>{t('trading.tab2.title')}</div>
              <div className={`${styles.firstBlockText} font-16-14`}><Trans>{t('trading.tab2.text')}</Trans></div>
              <div className={`${styles.btn} mt-btn`}><Button bold>{t('btn.start')}</Button></div>
              <div className={styles.imgWrap}><img src={bgs[1]} alt='' /></div>
            </div>

            <div className={styles.gridListWrap}>
              <div className={`${styles.tabItemTitle} font-32-24`}>{t('trading.tab2.list.title')}</div>
              <div className={styles.gridList}>
                {list2.map((item, index) => (
                  <div className={styles.gridListItem} key={index}>
                    <div className={`${styles.gridListItemTitle} font-24-20`}>{t(`${item.title}`)}</div>
                    <div className={`${styles.gridListItemText} font-16-14`}>{t(`${item.text}`)}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className={`${styles.tabItem} ${2 === activeTab ? styles.tabItemActive : ''}`}>
            <div className={styles.firstBlock}>
              <div className={`${styles.firstBlockTitle} font-40-28`}>{t('trading.tab3.title')}</div>
              <div className={`${styles.firstBlockText} font-16-14`}><Trans>{t('trading.tab3.text')}</Trans></div>
              <div className={`${styles.btn} mt-btn`}><Button bold>{t('btn.start')}</Button></div>
              <div className={styles.imgWrap}><img src={bgs[2]} alt='' /></div>
            </div>

            <div className={styles.gridListWrap}>
              <div className={`${styles.tabItemTitle} font-32-24`}>{t('trading.tab3.list.title')}</div>
              <div className={styles.gridList}>
                {list3.map((item, index) => (
                  <div className={styles.gridListItem} key={index}>
                    <div className={`${styles.gridListItemTitle} font-24-20`}>{t(`${item.title}`)}</div>
                    <div className={`${styles.gridListItemText} font-16-14`}>{t(`${item.text}`)}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className={`${styles.tabItem} ${3 === activeTab ? styles.tabItemActive : ''}`}>
            <div className={styles.firstBlock}>
              <div className={`${styles.firstBlockTitle} font-40-28`}>{t('trading.tab4.title')}</div>
              <div className={`${styles.firstBlockText} font-16-14`}><Trans>{t('trading.tab4.text')}</Trans></div>
              <div className={`${styles.btn} mt-btn`}><Button bold>{t('btn.start')}</Button></div>
              <div className={styles.imgWrap}><img src={bgs[3]} alt='' /></div>
            </div>

            <div className={styles.gridListWrap}>
              <div className={`${styles.tabItemTitle} font-32-24`}>{t('trading.tab4.list.title')}</div>
              <div className={styles.gridList}>
                {list4.map((item, index) => (
                  <div className={styles.gridListItem} key={index}>
                    <div className={`${styles.gridListItemTitle} font-24-20`}>{t(`${item.title}`)}</div>
                    <div className={`${styles.gridListItemText} font-16-14`}>{t(`${item.text}`)}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className={`${styles.tabItem} ${4 === activeTab ? styles.tabItemActive : ''}`}>
            <div className={styles.firstBlock}>
              <div className={`${styles.firstBlockTitle} font-40-28`}>{t('trading.tab5.title')}</div>
              <div className={`${styles.firstBlockText} font-16-14`}><Trans>{t('trading.tab5.text')}</Trans></div>
              <div className={`${styles.btn} mt-btn`}><Button bold>{t('btn.start')}</Button></div>
              <div className={styles.imgWrap}><img src={bgs[4]} alt='' /></div>
            </div>

            <div className={styles.gridListWrap}>
              <div className={`${styles.tabItemTitle} font-32-24`}>{t('trading.tab5.list.title')}</div>
              <div className={styles.gridList}>
                {list5.map((item, index) => (
                  <div className={styles.gridListItem} key={index}>
                    <div className={`${styles.gridListItemTitle} font-24-20`}>{t(`${item.title}`)}</div>
                    <div className={`${styles.gridListItemText} font-16-14`}>{t(`${item.text}`)}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className={`${styles.tabItem} ${5 === activeTab ? styles.tabItemActive : ''}`}>
            <div className={styles.firstBlock}>
              <div className={`${styles.firstBlockTitle} font-40-28`}>{t('trading.tab6.title')}</div>
              <div className={`${styles.firstBlockText} font-16-14`}><Trans>{t('trading.tab6.text')}</Trans></div>
              <div className={`${styles.btn} mt-btn`}><Button bold>{t('btn.start')}</Button></div>
              <div className={styles.imgWrap}><img src={bgs[5]} alt='' /></div>
            </div>

            <div className={styles.gridListWrap}>
              <div className={`${styles.tabItemTitle} font-32-24`}>{t('trading.tab6.list.title')}</div>
              <div className={styles.gridList}>
                {list6.map((item, index) => (
                  <div className={styles.gridListItem} key={index}>
                    <div className={`${styles.gridListItemTitle} font-24-20`}>{t(`${item.title}`)}</div>
                    <div className={`${styles.gridListItemText} font-16-14`}>{t(`${item.text}`)}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className={`${styles.tabItem} ${6 === activeTab ? styles.tabItemActive : ''}`}>
            <div className={styles.firstBlock}>
              <div className={`${styles.firstBlockTitle} font-40-28`}>{t('trading.tab7.title')}</div>
              <div className={`${styles.firstBlockText} font-16-14`}><Trans>{t('trading.tab7.text')}</Trans></div>
              <div className={`${styles.btn} mt-btn`}><Button bold>{t('btn.start')}</Button></div>
              <div className={styles.imgWrap}><img src={bgs[6]} alt='' /></div>
            </div>

            <div className={styles.gridListWrap}>
              <div className={`${styles.tabItemTitle} font-32-24`}>{t('trading.tab7.list.title')}</div>
              <div className={styles.gridList}>
                {list7.map((item, index) => (
                  <div className={styles.gridListItem} key={index}>
                    <div className={`${styles.gridListItemTitle} font-24-20`}>{t(`${item.title}`)}</div>
                    <div className={`${styles.gridListItemText} font-16-14`}>{t(`${item.text}`)}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tabs;