import React, { useState } from "react";
import styles from './Slider.module.scss';
import { Trans, useTranslation } from "react-i18next";
import Button from "../../GlodalComponents/Button/Button";
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';

import icon from '../../../assets/images/acctypes/arr-right.svg';

const Slider = () => {
  const { t } = useTranslation();
  const [swiper, setSwiper] = useState({});
  const [active, setActive] = useState(0);
  const slides = t('acctypes.sec.slider', { returnObjects: true });

  return (
      <section className={styles.wrap}>
        <div className={`container`}>
          <div className={styles.sliderWrap}>
            <Swiper
              modules={[Navigation]}
              spaceBetween={19}
              slidesPerGroup={1}
              loop={false}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                769: {
                  slidesPerView: 2.2,
                },
                1024: {
                  slidesPerView: 3.2,
                }
              }}
              onInit={(e) => { setSwiper(e) }}
              onSlideChange={(e) => setActive(e.activeIndex)}
            >
              {slides.map((item, index) => (
                <SwiperSlide key={index} className={`${styles.sliderItem} ${styles[item.title.toLowerCase()]}`}>
                  <div className={`${styles.slideTitle} font-24-20`}>{item.title}</div>
                  <div className={styles.slideList}>
                    {item.list.map((listItem, listIndex) => (
                      <div className={`${styles.slideListItem} font-16-14`} key={listIndex}>
                        <span><Trans>{t(`${listItem.title}`)}</Trans></span>
                        <span className={styles.centered}><Trans>{t(`${listItem.text}`)}</Trans></span>
                      </div>
                    ))}
                    <div className="mt-btn"><Button bold>{t('btn.open_acc')}</Button></div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className={styles.sliderBtns}>
            <button 
              className={`${styles.sliderBtn} ${styles.sliderBtnPrev} ${active === 0 ? styles.inactive : ''}`}
              onClick={() => swiper.slidePrev()}
            >
              <img src={icon} alt="Prev" />
            </button>
            <button 
              className={`${styles.sliderBtn} ${styles.sliderBtnNext} ${active === swiper.snapGrid?.length - 1 ? styles.inactive : ''}`}
              onClick={() => swiper.slideNext()}
            >
              <img src={icon} alt="Next" />
            </button>
          </div>
        </div>
      </section>
  );
};

export default Slider;