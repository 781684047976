import React from "react";
import styles from './TextMarquee.module.scss';
import { Trans, useTranslation } from "react-i18next";
import Button from "../../GlodalComponents/Button/Button";
import Marquee from "react-fast-marquee";
import { Fade } from "react-reveal";

const TextMarquee = () => {
  const { t } = useTranslation();
  const list = t('main.six.list', { returnObjects: true });
  const marqueeList = [...list, ...list];

  return (
    <section className={styles.wrap}>
      <div className={`${styles.container} container`}>
        <div className={styles.row}>
          <div>
            <Fade right><div className={`${styles.title} font-40-28`}><Trans>{t('main.six.title')}</Trans></div></Fade>
            <Fade left delay={50}><div className={`${styles.text} font-16-14`}><Trans>{t('main.six.text')}</Trans></div></Fade>
          </div>
          <Fade right delay={100}>
            <div className={`mt-btn ${styles.btn}`}>
              <Button bold>{t('btn.start')}</Button>
            </div>
          </Fade>
        </div>
        <div className={styles.marqueeWrap}>
          <Marquee>
            {marqueeList.map((item, index) => (
              <div className={styles.marqueeItem} key={index}><Trans>{t(`${item.title}`)}</Trans></div>
            ))}
          </Marquee>
        </div>
      </div>
    </section>
  );
};

export default TextMarquee;