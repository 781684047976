import React from "react";
import styles from './Exp.module.scss';
import { Trans, useTranslation } from "react-i18next";
import { Fade } from "react-reveal";

import image from '../../../assets/images/homepage/image3.webp';

const Exp = () => {
  const { t } = useTranslation();
  const list = t('main.four.list', { returnObjects: true });

  return (
    <section className={styles.wrap}>
      <div className={`${styles.container} container`}>
        <div className={styles.left}>
          <Fade right><Trans><div className={`font-40-28 ${styles.title}`}>{t('main.four.title')}</div></Trans></Fade>
          <div className={styles.list}>
            {list.map((item, index) => (
            <div className={styles.listItem} key={index}>
              <div className={`${styles.listTitle} font-32-20`}>
                <Trans>{t(`${item.title}`)}</Trans>
              </div>
              <div className={`${styles.listText} font-20-16`}>
                <Trans>{t(`${item.text}`)}</Trans>
              </div>
            </div>
            ))}
          </div>
        </div>
        <div className={styles.imgWrap}>
          <img src={image} alt="" />
        </div>
      </div>
    </section>
  );
};

export default Exp;