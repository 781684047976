import React from 'react';
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from '../components/GlodalComponents/Header/Header';
import Footer from '../components/GlodalComponents/Footer/Footer';
import Banner from '../components/WhyPage/Banner/Banner';
import TextImgGrid from '../components/WhyPage/TextImgGrid/TextImgGrid';

function WhyPage() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Rebel Finance · Why Rebel Finance</title>
        <meta name="title" content="Why Choose Rebel Finance? Unraveling the Benefits of Our Trading Platform" />
        <meta property="og:title" content="Why Choose Rebel Finance? Unraveling the Benefits of Our Trading Platform" />
        <meta property="twitter:title" content="Why Choose Rebel Finance? Unraveling the Benefits of Our Trading Platform" />

        <meta name="description" content="Explore the unique advantages that set Rebel Finance apart. From cutting-edge technology to unparalleled customer support, discover why traders around the world choose Rebel Finance for a superior trading experience." />
        <meta property="og:description" content="Explore the unique advantages that set Rebel Finance apart. From cutting-edge technology to unparalleled customer support, discover why traders around the world choose Rebel Finance for a superior trading experience." />
        <meta property="twitter:description" content="Explore the unique advantages that set Rebel Finance apart. From cutting-edge technology to unparalleled customer support, discover why traders around the world choose Rebel Finance for a superior trading experience." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className='why-page'>
        <Header />
        <Banner />
        <TextImgGrid />
        <Footer />
      </div>
    </HelmetProvider>
  )
  
}

export default WhyPage;
