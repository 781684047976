import React from "react";
import styles from './Grid.module.scss';
import { Trans, useTranslation } from "react-i18next";
import { Fade } from "react-reveal";

import icon1 from '../../../assets/images/homepage/icon1.webp';
import icon2 from '../../../assets/images/homepage/icon2.webp';
import icon3 from '../../../assets/images/homepage/icon3.webp';
import icon4 from '../../../assets/images/homepage/icon4.webp';

const Grid = () => {
  const { t } = useTranslation();
  const list = t('main.third.list', { returnObjects: true });
  const icons = [icon1, icon2, icon3, icon4];

  return (
    <section className={styles.wrap}>
      <div className={`${styles.container} container`}>
          <Fade right><Trans><div className={`font-40-28 ${styles.title}`}>{t('main.third.title')}</div></Trans></Fade>
          <div className={styles.list}>
            {list.map((item, index) => (
            <div className={styles.listItem} key={index}>
              <img src={icons[index]} alt="" className={styles.icon} />
              <div className={`${styles.listTitle} font-32-20`}>
                <Trans>{t(`${item.title}`)}</Trans>
              </div>
              <div className={`${styles.listText} font-20-16`}>
                <Trans>{t(`${item.text}`)}</Trans>
              </div>
            </div>
            ))}
          </div>
      </div>
    </section>
  );
};

export default Grid;