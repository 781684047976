import React from "react";
import styles from './Partners.module.scss';
import { useTranslation } from "react-i18next";
import Marquee from "react-fast-marquee";
import icon_01 from '../../../assets/images/homepage/partners/icon_01.png';
import icon_02 from '../../../assets/images/homepage/partners/icon_02.png';
import icon_03 from '../../../assets/images/homepage/partners/icon_03.png';
import icon_04 from '../../../assets/images/homepage/partners/icon_04.png';
import icon_05 from '../../../assets/images/homepage/partners/icon_05.png';
import icon_06 from '../../../assets/images/homepage/partners/icon_06.png';
import icon_07 from '../../../assets/images/homepage/partners/icon_07.png';
import icon_08 from '../../../assets/images/homepage/partners/icon_08.png';
import icon_09 from '../../../assets/images/homepage/partners/icon_09.png';

const cards = [
    {
        icon: icon_01,
    },
    {
        icon: icon_02,
    },
    {
        icon: icon_03,
    },
    {
        icon: icon_04,
    },
    {
        icon: icon_05,
    },
    {
        icon: icon_06,
    },
    {
        icon: icon_07,
    },
    {
        icon: icon_08,
    },
    {
        icon: icon_09,
    },
];

const Partners = () => {
    const { t } = useTranslation();
    return (
        <section className={`${styles.wrap} ovf-hidden`}>
            <div className={styles.partners}>
                <div className="container">
                    <h2 className={`${styles.title} font-40-28`}>
                        {t('home_partners_title')}
                    </h2>
                </div>
                <div className={styles.cards}>
                    <div className={`${styles.marquee} ${styles.marquee_02}`}>
                        <Marquee autoFill>
                            <div className={styles.content}>
                                {cards.map((item, index) =>
                                    <img className={styles.icon} key={index} src={item.icon} alt='' />
                                )}
                            </div>
                        </Marquee>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Partners;