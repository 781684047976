import React from "react";
import styles from './ListText.module.scss';
import { useTranslation } from "react-i18next";

const ListText = () => {
  const { t } = useTranslation();
  const list = t('about.four.list', { returnObjects: true });

  return (
    <section className={styles.wrap}>
      <div className={`${styles.container} container`}>
          <div className={`${styles.title} font-40-28`}>{t('about.four.title')}</div>
          <div className={styles.list}>
            {list.map((item, index) => (
              <div className={styles.listItem} key={index}>
                <div className={`${styles.listItemTitle} font-24-20`}>{t(`${item.title}`)}</div>
                <div className={`${styles.listItemText} font-16-14`}>{t(`${item.text}`)}</div>
              </div>
            ))}
          </div>
      </div>
    </section>
  );
};

export default ListText;