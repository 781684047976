import React from "react";
import styles from './ColText.module.scss';
import { useTranslation } from "react-i18next";
import Button from "../../GlodalComponents/Button/Button";

const ColText = () => {
  const { t } = useTranslation();

  return (
    <section className={styles.wrap}>
      <div className={`${styles.container} container`}>
        <div className={styles.item}>
          <div className={`${styles.title} font-32-24`}>{t('promotion.sec.title')}</div>
          <div className={`${styles.text} font-20-16`}>{t('promotion.sec.text1')}</div>
          <div className='mt-btn'>
            <Button bold>{t('btn.start')}</Button>
          </div>
        </div>
        <div className={styles.item}>
          <div className={`${styles.text} font-20-16`}>{t('promotion.sec.text2')}</div>
          <div className={`${styles.text} font-16-14`}>{t('promotion.sec.text3')}</div>
        </div>
      </div>
    </section>
  );
};

export default ColText;