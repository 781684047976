import React from 'react';
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from '../components/GlodalComponents/Header/Header';
import Footer from '../components/GlodalComponents/Footer/Footer';
import Banner from '../components/AccountsPage/Banner/Banner';
import Slider from '../components/AccountsPage/Slider/Slider';
import TextImage from '../components/AccountsPage/TextImage/TextImage';
import BgText from '../components/AccountsPage/BgText/BgText';

function AccountsPage() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Rebel Finance · Account types</title>
        <meta name="title" content="Tailored Trading Experiences with Rebel Finance Account Types" />
        <meta property="og:title" content="Tailored Trading Experiences with Rebel Finance Account Types" />
        <meta property="twitter:title" content="Tailored Trading Experiences with Rebel Finance Account Types" />

        <meta name="description" content="Discover the perfect account type to match your trading goals at Rebel Finance. From beginner-friendly options to advanced accounts, find the ideal platform to elevate your trading journey." />
        <meta property="og:description" content="Discover the perfect account type to match your trading goals at Rebel Finance. From beginner-friendly options to advanced accounts, find the ideal platform to elevate your trading journey." />
        <meta property="twitter:description" content="Discover the perfect account type to match your trading goals at Rebel Finance. From beginner-friendly options to advanced accounts, find the ideal platform to elevate your trading journey." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className='account-page'>
        <Header />
        <Banner />
        <Slider />
        <TextImage />
        <BgText />
        <Footer />
      </div>
    </HelmetProvider>
  )
  
}

export default AccountsPage;
