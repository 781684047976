import React from "react";
import styles from './Banner.module.scss';
import { Trans, useTranslation } from "react-i18next";
import { Fade } from "react-reveal";

import image from '../../../assets/images/about/image1.webp';

const Banner = () => {
  const { t } = useTranslation();

  return (
    <section className={styles.wrap}>
      <div className={`${styles.container} container`}>
        <div className={styles.left}>
          <Fade left><h1 className={`${styles.title} font-40-28`}><Trans>{t('about.first.title')}</Trans></h1></Fade>
        </div>
        <div className={styles.right}>
          <Fade right delay={50}><h5 className={`${styles.text} font-16-14`}><Trans>{t('about.first.text0')}</Trans></h5></Fade>
          <Fade right delay={50}><h5 className={`${styles.text} font-16-14`}><Trans>{t('about.first.text1')}</Trans></h5></Fade>
          <Fade right delay={50}><h5 className={`${styles.text} font-16-14`}><Trans>{t('about.first.text2')}</Trans></h5></Fade>
        </div>
        <div className={styles.photo}>
            <Fade bottom>
              <img src={image} alt="" />
            </Fade>
          </div>
      </div>
    </section>
  );
};

export default Banner;