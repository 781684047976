import React from "react";
import styles from './ListText.module.scss';
import { useTranslation } from "react-i18next";

const ListText = () => {
  const { t } = useTranslation();
  const list = t('promotion.four.list', { returnObjects: true });
  const list1 = t('promotion.four.list1', { returnObjects: true });

  return (
    <section className={styles.wrap}>
      <div className={`${styles.container} container`}>
          <div className={`${styles.title} font-40-28`}>{t('promotion.four.title')}</div>
          <div className={styles.row}>

            <div className={styles.list}>
              {list.map((item, index) => (
                <div className={`${styles.listItem} font-16-14`} key={index}>{t(`${item.title}`)}</div>
              ))}
            </div>

            <div className={styles.list}>
              {list1.map((item, index) => (
                <div className={`${styles.listItem} font-16-14`} key={index}>{t(`${item.title}`)}</div>
              ))}
            </div>
        </div>
      </div>
    </section>
  );
};

export default ListText;