import React from "react";
import styles from './TextListImg.module.scss';
import { useTranslation } from "react-i18next";

import image from '../../../assets/images/promotion/image2.webp';

const TextImg = () => {
  const { t } = useTranslation();
  const list = t('promotion.third.list', { returnObjects: true });

  return (
    <section className={styles.wrap}>
      <div className={`${styles.container} container`}>
        <div className={styles.item}>
          <div className={`${styles.title} font-32-24`}>{t('promotion.third.title')}</div>
          <div className={styles.list}>
              {list.map((item, index) => (
                <div className={`${styles.listItem} font-16-14`} key={index}>
                  <div className={styles.listItemIndex}>
                    {<span>{index + 1 < 10 ? `0${index + 1}` : index + 1}</span>}
                  </div>
                  {t(`${item.title}`)}
                </div>
              ))}
            </div>
        </div>
        <div className={styles.item}>
          <img src={image} alt="" className={styles.image} />
        </div>
      </div>
    </section>
  );
};

export default TextImg;