import React from "react";
import styles from './BgText2.module.scss';
import { useTranslation } from "react-i18next";
import Button from "../../GlodalComponents/Button/Button";

import iconAddress from '../../../assets/images/icon-address.svg';
import iconTel from '../../../assets/images/icon-tel.svg';
import iconMail from '../../../assets/images/icon-email.svg';

const BgText = () => {
  const { t } = useTranslation();

  return (
    <section className={styles.wrap}>
      <div className={`${styles.container} container`}>

        <div className={styles.item}>
          <div className={`${styles.title} font-48-32`}>{t('about.five.title')}</div>
          <div className={`${styles.text} font-24-16`}>{t('about.five.text0')}</div>
          <div className={`${styles.text} font-24-16`}>{t('about.five.text1')}</div>
          <div className="mt-btn"><Button custom >{t('btn.contact')}</Button></div>
        </div>
        <div className={`${styles.item} ${styles.itemSec}`}>
          <div className={`${styles.text} font-16-14`}>{t('about.five.text2')}</div>
          <div className={`${styles.textBold} font-16-14`}>{t('about.five.text3')}</div>
          <div className={styles.contacts}>
            <div>
              <a href="tel:+11111111111" className={`${styles.contact} font-16-14`}>
              <i className={styles.icon}><img src={iconAddress} alt="" /></i>
                {t('about.five.adress')}
              </a>
            </div>
            <div>
              <a href="tel:+11111111111" className={`${styles.contact} font-16-14`}>
                <i className={styles.icon}><img src={iconTel} alt="" /></i>
                +11111111111
              </a>
            </div>
            <div>
              <a href={`Finease@support.com`} className={`${styles.contact} font-16-14 ${styles.mart}`}>
              <i className={styles.icon}><img src={iconMail} alt="" /></i>
                Finease@support.com
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BgText;