import HomePage from '../pages/HomePage';
import AccountsPage from '../pages/AccountsPage';
import AboutPage from '../pages/AboutPage';
import TradingPage from '../pages/TradingPage';
import WhyPage from '../pages/WhyPage';
import PromotionPage from '../pages/PromotionPage';

export const routes = [
    { path: '/:lang/', element: HomePage },
    { path: '/:lang/trading', element: TradingPage },
    { path: '/:lang/promotion', element: PromotionPage },
    { path: '/:lang/accounts', element: AccountsPage },
    { path: '/:lang/why', element: WhyPage },
    { path: '/:lang/about', element: AboutPage },
];


export const headerRoutes = [
    { path: 'trading', text: 'header.trading' },
    { path: 'promotion', text: 'header.promotion' },
    { path: 'accounts', text: 'header.accounts' },
    { path: 'why', text: 'header.why' },
    { path: 'about', text: 'header.about' },
];