import React from 'react';
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from '../components/GlodalComponents/Header/Header';
import Footer from '../components/GlodalComponents/Footer/Footer';
import Banner from '../components/HomePage/Banner/Banner';
import TextGrid from '../components/HomePage/TextGrid/TextGrid';
import Grid from '../components/HomePage/Grid/Grid';
import Exp from '../components/HomePage/Exp/Exp';
import TextImgGrid from '../components/HomePage/TextImgGrid/TextImgGrid';
import TextMarquee from '../components/HomePage/TextMarquee/TextMarquee';
import Partners from '../components/HomePage/Partners/Partners';

function HomePage() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Rebel Finance</title>
        <meta name="title" content="Welcome to Rebel Finance - Your Gateway to Seamless Trading Experiences" />
        <meta property="og:title" content="Welcome to Rebel Finance - Your Gateway to Seamless Trading Experiences" />
        <meta property="twitter:title" content="Welcome to Rebel Finance - Your Gateway to Seamless Trading Experiences" />

        <meta name="description" content="Explore the Main Page of Rebel Finance and discover a world of innovative trading solutions. Unlock opportunities, stay informed, and trade with confidence on our user-friendly platform." />
        <meta property="og:description" content="Explore the Main Page of Rebel Finance and discover a world of innovative trading solutions. Unlock opportunities, stay informed, and trade with confidence on our user-friendly platform." />
        <meta property="twitter:description" content="Explore the Main Page of Rebel Finance and discover a world of innovative trading solutions. Unlock opportunities, stay informed, and trade with confidence on our user-friendly platform." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className='main-page'>
        <Header />
        <Banner />
        <TextGrid />
        <Grid />
        <Exp />
        <Partners />
        <TextImgGrid />
        <TextMarquee />
        <Footer />
      </div>
    </HelmetProvider>
  )
}

export default HomePage;
