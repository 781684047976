import React from 'react';
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from '../components/GlodalComponents/Header/Header';
import Footer from '../components/GlodalComponents/Footer/Footer';
import Banner from '../components/AboutPage/Banner/Banner';
import TextImgGrid from '../components/AboutPage/TextImgGrid/TextImgGrid';
import BgText from '../components/AboutPage/BgText/BgText';
import ListText from '../components/AboutPage/ListText/ListText';
import BgText2 from '../components/AboutPage/BgText2/BgText2';

function AboutPage() {
  return (
    <HelmetProvider>
      <Helmet>
      <title>Rebel Finance · About us</title>
        <meta name="title" content="Get to Know Rebel Finance - Your Trusted Partner in Trading Success" />
        <meta property="og:title" content="Get to Know Rebel Finance - Your Trusted Partner in Trading Success" />
        <meta property="twitter:title" content="Get to Know Rebel Finance - Your Trusted Partner in Trading Success" />

        <meta name="description" content="Delve into the story of Rebel Finance and understand our commitment to empowering traders. Learn about our mission, values, and the team driving innovation in the financial markets." />
        <meta property="og:description" content="Delve into the story of Rebel Finance and understand our commitment to empowering traders. Learn about our mission, values, and the team driving innovation in the financial markets." />
        <meta property="twitter:description" content="Delve into the story of Rebel Finance and understand our commitment to empowering traders. Learn about our mission, values, and the team driving innovation in the financial markets." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className='about-page'>
        <Header />
        <Banner />
        <TextImgGrid />
        <BgText />
        <ListText />
        <BgText2 />
        <Footer />
      </div>
    </HelmetProvider>
  )
  
}

export default AboutPage;
