import React from "react";
import styles from './BgText.module.scss';
import { useTranslation } from "react-i18next";

import image from '../../../assets/images/acctypes/image3.webp';
import iconTel from '../../../assets/images/icon-tel.svg';
import iconMail from '../../../assets/images/icon-email.svg';

const BgText = () => {
  const { t } = useTranslation();

  return (
    <section className={styles.wrap}>
      <div className={`${styles.container} container`}>
        <div className={styles.item}>
          <img src={image} alt="" className={styles.image} />
        </div>

        <div className={styles.item}>
          <div className={`${styles.title} font-64-40`}>{t('acctypes.four.title')}</div>
          <div className={`${styles.text} font-24-16`}>{t('acctypes.four.text')}</div>
          <div>
            <a href="tel:+11111111111" className={`${styles.contact} font-16-14`}>
              <img src={iconTel} alt="" />
              +11111111111
            </a>
          </div>
          <div>
            <a href={`Finease@support.com`} className={`${styles.contact} font-16-14 ${styles.mart}`}>
              <img src={iconMail} alt="" />
              Finease@support.com
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BgText;