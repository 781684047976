import React from "react";
import styles from './Banner.module.scss';
import { Trans, useTranslation } from "react-i18next";
import Button from "../../GlodalComponents/Button/Button";
import { Fade } from "react-reveal";

import image from '../../../assets/images/homepage/image1.webp';
import bg from '../../../assets/images/homepage/imagebg.webp';

const Banner = () => {
  const { t } = useTranslation();

  return (
    <section className={styles.wrap}>
      <div className={`${styles.container} container`}>
        <div className={styles.left}>
          <Fade right><h1 className={`${styles.title} font-48-26`}><Trans>{t('main.first.title')}</Trans></h1></Fade>
          <Fade left delay={50}><h5 className={`${styles.text} font-16-14`}><Trans>{t('main.first.text')}</Trans></h5></Fade>
          <Fade right delay={100}>
            <div className='mt-btn'>
              <Button bold>{t('btn.start')}</Button>
            </div>
          </Fade>
        </div>
        <div className={`${styles.abs}`}>
          <Fade bottom delay={200}><img src={bg} alt="" className={styles.bg} /></Fade>
        </div>
        <Fade bottom>
          <div className={styles.right}>
            <img src={image} alt="" className={styles.photo} />
          </div>
        </Fade>
      </div>
    </section>
  );
};

export default Banner;