import React from "react";
import styles from './TextImage.module.scss';
import { useTranslation } from "react-i18next";

import image from '../../../assets/images/promotion/image3.webp';

const TextImage = () => {
  const { t } = useTranslation();

  return (
    <section className={styles.wrap}>
      <div className={`${styles.container} container`}>
        <div className={styles.item}>
          <div className={`${styles.title} font-40-28`}>{t('promotion.five.title')}</div>
          <div className={`${styles.subtitle} font-20-16`}>{t('promotion.five.subtitle')}</div>
          <div className={`${styles.text} font-16-14`}>{t('promotion.five.text0')}</div>
          <div className={`${styles.text} font-16-14`}>{t('promotion.five.text1')}</div>
          <div className={`${styles.subtitle} font-20-16`}>{t('promotion.five.subtitle1')}</div>
          <div className={`${styles.text} font-16-14`}>{t('promotion.five.text2')}</div>
        </div>

        <div className={styles.item}>
          <img src={image} alt="" className={styles.image} />
        </div>
      </div>
    </section>
  );
};

export default TextImage;